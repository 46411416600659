* {
    transition: 0.4s ease-in-out;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

@layer utilities {
    @variants responsive {
        .masonry {
            column-count: 4;
            column-gap: 2rem;
        }
        .masonry-2 {
            column-count: 3;
            column-gap: 2rem;
        }
        .masonry-1 {
            column-count: 2;
            column-gap: 1rem;
        }
        .break-inside {
            break-inside: avoid;
        }
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 27, 27, 0.8);
    display: flex;
    align-items: center;
}

.overlay2 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.overlay > span {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: #ffffff;
    z-index: 999;
    cursor: pointer;
}
.overlay-arrows_left {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 999;
    margin: 2rem;
}

.overlay-arrows_right {
    display: flex;
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 999;
    margin: 2rem;
}

* {
    box-sizing: border-box;
}
